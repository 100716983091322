import { useState, useEffect } from 'react'

//const apiUrl = 'http://localhost/doctor/API/';
//export const apiUrl = '/doctor/APIs/'
export const apiUrl = '/APIs/'

export function useGetSymptoms() {
  const [items, setItems] = useState<never[]>([])
  //getSymptoms.php
  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'userAPI.php?controller=symptomsController&method=getList').then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [])

  return items
}

export function useGetSymptomsList() {
  const [items, setItems] = useState<never[]>([])

  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'getSymptomsList.php').then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [])

  return items
}

export function useGetList() {
  const [items, setItems] = useState<never[]>([])

  useEffect(() => {
    async function fetchData() {
      var data = await fetch(apiUrl + 'userAPI.php?controller=sicknesController&method=getList').then((res) => {
        return res.json()
      })
      setItems(data)
    }
    fetchData()
  }, [])

  return items
}

export function UseCreateRecord(json: any) {
  //const [items, setItems] = useState<never[]>([])

  json.covidTest = json.covidTest ? 1 : 0
  json.labaratoryTest = json.labaratoryTest ? 1 : 0
  json.pneumococcalVaccine = json.pneumococcalVaccine ? 1 : 0
  json.seasonalVaccine = json.seasonalVaccine ? 1 : 0
  json.selfTest = json.selfTest ? 1 : 0

  json.medicinesFever = json.medicinesFever ? 1 : 0
  json.medicinesCough = json.medicinesCough ? 1 : 0
  json.medicinesPain = json.medicinesPain ? 1 : 0
  json.smoke = json.smoke ? 1 : 0
  json.vape = json.vape ? 1 : 0
  json.vapeYear = json.vapeYear ? 1 : 0

  //useEffect(() => {
  async function fetchData() {
    //var data =
    await fetch(apiUrl + 'userAPI.php?controller=sicknesController&method=create', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((res) => {
      return res.json()
    })
    //setItems(data)
  }
  fetchData()
  //}, [])

  //return items
}
