//import { Box, Tab, Tabs } from '@mui/material'
import React, { FunctionComponent/*, useState*/ } from 'react'

import { Outlet/*, useNavigate*/ } from 'react-router-dom'

const RootPage: FunctionComponent = () => {
  //const navigate = useNavigate()
/*
  const [pageIndex, setPageIndex] = useState<number>(0)

  
  const handlePageChange = (event: React.SyntheticEvent, newValue: number) => {
    setPageIndex(newValue)

    if (newValue === 0) {
      navigate('/new')
    } else if (newValue === 1) {
      navigate('/list')
    }
  }
*/
  return (
    <>
    {
      /*
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={pageIndex} onChange={handlePageChange} variant='scrollable' scrollButtons='auto'>
          <Tab label='Jauna anketa' />
          <Tab label='Anketes' />
        </Tabs>
      </Box>
      */
    }
      <Outlet />
    </>
  )
}

export default RootPage
