import { FC } from 'react'
import QuestionnaireComponent from './QuestionnaireComponent'

import { useGetSymptoms, useGetSymptomsList } from '../API/GetSymptoms'

const Questionnaire: FC = () => {
  const symptoms = useGetSymptoms()
  const symptomsList = useGetSymptomsList()

  return <QuestionnaireComponent symptoms={symptoms} symptomsList={symptomsList} />
}

export default Questionnaire
