import { FC } from 'react'
import ListQuestionnairesComponent from './ListQuestionnairesComponent'

import { useGetList, useGetSymptoms } from '../API/GetSymptoms'

const ListQuestionnaires: FC = () => {
  const getList = useGetList()
  const getSymptoms = useGetSymptoms()

  return <ListQuestionnairesComponent getList={getList} getSymptoms={getSymptoms} />
}

export default ListQuestionnaires
