export const IS_CHROME = navigator.userAgent.includes('Chrome')
export const DEFAULT_BOX_WIDTH = 500
export const DATE_FORMAT = 'DD/MM/YYYY'
export const SMOKE_FROM = 14

export const SymptomImagesList = [
  { symptomId: 'symptom1', source: 'img/symptom1.jpg', symptomName: 'Temperatūra' },
  { symptomId: 'symptom2', source: 'img/symptom2.jpg', symptomName: 'Drebuļi un aukstuma sajūta' },
  { symptomId: 'symptom4', source: 'img/symptom4.jpg', symptomName: 'Galvas sāpes' },
  { symptomId: 'symptom5', source: 'img/symptom5.jpg', symptomName: 'Iesnas' },
  { symptomId: 'symptom10', source: 'img/symptom10.jpg', symptomName: 'Kasīšanas sajūta vai sāpes rīklē' },
]

export enum GenderType {
  MALE = 'Vīrietis',
  FEMALE = 'Sieviete',
}

export enum PositiveNegativeType {
  POSITIVE = 'pozitīvs',
  NEGATIVE = 'negatīs',
}

export interface ICurrentDimension {
  width: number
  height: number
}

export interface IQuestionnaire {
  pacientNR: string
  genderType?: GenderType
  age: string
  //fillDate: string
  symptomDate: string
  temperatureDate: string
  temperature: string
  seasonalVaccine: boolean
  pneumococcalVaccine: boolean

  medicinesFever: boolean
  medicinesCough: boolean
  medicinesPain: boolean
  medicinesOther: string

  smoke: boolean | null
  smokePerDay: string
  vape: boolean | null
  vapeYear: boolean

  covidTest: boolean | null
  selfTest: boolean
  selfTestResult: string
  selfTestDate: string
  labaratoryTest: boolean
  labaratoryTestResult: string
  labaratoryTestDate: string
  existence: string
  intensity: string
  dynamics: string
  comments: string
}

export interface IAnsweredSymptom {
  symptomId: string
  existence: string
  intensity: string
  dynamics: string
}

export const getCurrentDimension = () => {
  const currentDimension: ICurrentDimension = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  return currentDimension
}

export const getMinBoxWidth = (currentDimension: ICurrentDimension) => {
  if (currentDimension.width <= 1000) {
    return DEFAULT_BOX_WIDTH
  } else {
    return DEFAULT_BOX_WIDTH * 2
  }
}

export const AgeList: number[] = []
for (let i = 0; i <= 100; i++) {
  AgeList.push(i)
}
