import React, { FC, useState, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'

import { Box, Paper, Grid, Button, TextField, Typography } from '@mui/material'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox } from '@mui/material'

import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import { InputLabel, MenuItem } from '@mui/material'

//import { useNavigate } from 'react-router-dom'

import SendIcon from '@mui/icons-material/Send'

import {
  DEFAULT_BOX_WIDTH,
  DATE_FORMAT,
  SMOKE_FROM,
  SymptomImagesList,
  GenderType,
  AgeList,
  PositiveNegativeType,
  getCurrentDimension,
  getMinBoxWidth,
  IQuestionnaire,
  IAnsweredSymptom,
} from '../utils'

import { UseCreateRecord } from '../API/GetSymptoms'

const dynamicsHight = require('../../img/hight.jpg')
const dynamicsLow = require('../../img/low.jpg')
const dynamicsMiddle = require('../../img/middle.jpg')

dayjs.Ls.en.weekStart = 1
const today = dayjs()
const lastDays = dayjs().subtract(24, 'day')

export interface QuestionnaireProps {
  symptoms: any[]
  symptomsList: any[]
}

const answeredSymptoms: IAnsweredSymptom[] = []

const Questionnaire: FC<QuestionnaireProps> = ({ symptoms, symptomsList }) => {
  //const navigate = useNavigate()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const pacientPreset = urlParams.get('pacient')

  //console.log(pacientPreset);

  const [screenSize, setScreenSize] = useState(getCurrentDimension())
  const [minBoxWidth, setMinBoxWidth] = useState<number>(getMinBoxWidth(screenSize))
  const [showIntensity, setShowIntensity] = useState<boolean>(false)
  const [showDynamics, setShowDynamics] = useState<boolean>(false)
  const [currentSymptomKey, setCurrentSymptomKey] = useState<number>(0)

  const defaultErrors = {
    pacientNR: false,
    genderType: false,
    age: false,
    //fillDate: false,
    symptomDate: false,
    temperatureDate: false,
    temperature: false,
    //seasonalVaccine: false,
    //pneumococcalVaccine: false,
    covidTest: false,

    smoke: false,
    smokePerDay: false,
    vape: false,

    //selfTest: false,
    selfTestResult: false,
    selfTestDate: false,
    //labaratoryTest: false,
    labaratoryTestResult: false,
    labaratoryTestDate: false,
    existence: false,
    intensity: false,
    dynamics: false,
    comments: false,
  }

  const [errors, setErrors] = useState(Object.assign({}, defaultErrors))
  const [form, setForm] = useState<IQuestionnaire>({
    pacientNR: pacientPreset || '',
    genderType: undefined,
    age: '',
    //fillDate: dayjs().format('L'),
    symptomDate: '',
    temperatureDate: '',
    temperature: '',
    seasonalVaccine: false,
    pneumococcalVaccine: false,

    medicinesFever: false,
    medicinesCough: false,
    medicinesPain: false,
    medicinesOther: '',

    smoke: null,
    smokePerDay: '',
    vape: null,
    vapeYear: false,

    covidTest: null,

    selfTest: false,
    selfTestResult: '',
    selfTestDate: '',

    labaratoryTest: false,
    labaratoryTestResult: '',
    labaratoryTestDate: '',

    existence: '',
    intensity: '',
    dynamics: '',
    comments: '',
  })

  const currentSymptom = useMemo(() => {
    if (symptomsList && symptomsList[currentSymptomKey]) {
      return symptomsList[currentSymptomKey]
    } else {
      return false
    }
  }, [symptomsList, currentSymptomKey])

  const symptomImage = useMemo(() => {
    return currentSymptom && SymptomImagesList.find((obj) => obj.symptomId === currentSymptom.symptomId)
  }, [currentSymptom])

  const getErrors = () => {
    const newErrors = Object.assign({}, defaultErrors)
    let errorCount: number = 0

    if (form.pacientNR === '') {
      newErrors.pacientNR = true
      errorCount++
    }

    if (!form.genderType) {
      newErrors.genderType = true
      errorCount++
    }

    if (form.age === '') {
      newErrors.age = true
      errorCount++
    }

    if (parseInt(form.age) >= SMOKE_FROM)
    {
      if (form.smoke === null) {
        newErrors.smoke = true
        errorCount++
      }

      if (form.smoke === true && form.smokePerDay === '') {
        newErrors.smokePerDay = true
        errorCount++
      }

      if (form.vape === null) {
        newErrors.vape = true
        errorCount++
      }
    }

    /*
    if (form.fillDate === '') {
      newErrors.fillDate = true
      errorCount++
    }
*/
    if (form.symptomDate === '') {
      newErrors.symptomDate = true
      errorCount++
    }

    if (form.temperature === '') {
      newErrors.temperature = true
      errorCount++
    }

    if (form.temperatureDate === '') {
      newErrors.temperatureDate = true
      errorCount++
    }

    if (form.covidTest === null) {
      newErrors.covidTest = true
      errorCount++
    }

    if (form.selfTest) {
      if (form.selfTestDate === '') {
        newErrors.selfTestDate = true
        errorCount++
      }

      if (form.selfTestResult === '') {
        newErrors.selfTestResult = true
        errorCount++
      }
    }

    if (form.labaratoryTest) {
      if (form.labaratoryTestDate === '') {
        newErrors.labaratoryTestDate = true
        errorCount++
      }

      if (form.labaratoryTestResult === '') {
        newErrors.labaratoryTestResult = true
        errorCount++
      }
    }

    if (form.existence === '') {
      newErrors.existence = true
      errorCount++
    }

    if (form.existence === 'now') {
      if (form.intensity === '') {
        newErrors.intensity = true
        errorCount++
      }

      if (form.dynamics === '') {
        newErrors.dynamics = true
        errorCount++
      }
    }

    if (form.existence === 'was') {
      if (form.intensity === '') {
        newErrors.intensity = true
        errorCount++
      }
    }

    setErrors({
      ...newErrors,
    })

    return errorCount
  }

  const handleNextSymptom = () => {
    const errorCount = getErrors()

    if (errorCount === 0 && currentSymptomKey < symptomsList.length - 1) {
      answeredSymptoms.push({
        symptomId: currentSymptom.symptomId,
        existence: form.existence,
        intensity: form.intensity,
        dynamics: form.dynamics,
      })

      setShowIntensity(false)
      setShowDynamics(false)

      setForm({
        ...form,
        existence: '',
        intensity: '',
        dynamics: '',
      })

      setCurrentSymptomKey(currentSymptomKey + 1)
    }
  }

  const handleSaveForm = async () => {
    const errorCount = getErrors()

    if (errorCount === 0) {
      answeredSymptoms.push({
        symptomId: currentSymptom.symptomId,
        existence: form.existence,
        intensity: form.intensity,
        dynamics: form.dynamics,
      })

      const saveData = {
        pacientNR: form.pacientNR,
        genderType: form.genderType,
        age: form.age,
        //fillDate: form.fillDate,
        symptomDate: form.symptomDate,
        temperatureDate: form.temperatureDate,
        temperature: form.temperature,
        seasonalVaccine: form.seasonalVaccine,
        pneumococcalVaccine: form.pneumococcalVaccine,

        covidTest: form.covidTest,

        selfTest: form.selfTest,
        selfTestResult: form.selfTestResult,
        selfTestDate: form.selfTestDate,

        labaratoryTest: form.labaratoryTest,
        labaratoryTestResult: form.labaratoryTestResult,
        labaratoryTestDate: form.labaratoryTestDate,

        medicinesFever: form.medicinesFever,
        medicinesCough: form.medicinesCough,
        medicinesPain: form.medicinesPain,
        medicinesOther: form.medicinesOther,

        smoke: form.smoke,
        smokePerDay: form.smokePerDay,
        vape: form.vape,
        vapeYear: form.vapeYear,

        answeredSymptoms: answeredSymptoms,
        comments: form.comments,
      }

      await UseCreateRecord(saveData)
      alert('Simptomu dati saglabāti!')
      //navigate('http://aidamed.lv/')
      window.location.href = 'http://aidamed.lv/'
    }
  }

  const handlerChangeText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  const handleChangeGenderType = (event: SelectChangeEvent) => {
    setForm({
      ...form,
      genderType: event.target.value as GenderType,
    })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  const handlerChangeDate = (value: Dayjs | null, field: string) => {
    if (value) {
      setForm({
        ...form,
        [field]: value.format('L'),
      })
    }
  }

  const handleChangeCheckbox = (field: string, checked: boolean) => {
    setForm({
      ...form,
      [field]: checked,
    })
  }

  const handleChangeCovidTest = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setForm({
      ...form,
      covidTest: value === 'true' ? true : false,
    })
  }

  const handleChangeSmoke = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setForm({
      ...form,
      smoke: value === 'true' ? true : false,
    })
  }

  const handleChangeVape = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setForm({
      ...form,
      vape: value === 'true' ? true : false,
    })
  }

  const handleExistence = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value

    if (value === 'now') {
      setForm({
        ...form,
        existence: value,
      })

      setShowIntensity(true)
      setShowDynamics(true)
    }

    if (value === 'was') {
      setForm({
        ...form,
        existence: value,
        dynamics: '',
      })

      setShowIntensity(true)
      setShowDynamics(false)
    }

    if (value === 'never') {
      setForm({
        ...form,
        existence: value,
        intensity: '',
        dynamics: '',
      })

      setShowIntensity(false)
      setShowDynamics(false)
    }
  }

  const handleIntensity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setForm({
      ...form,
      intensity: value,
    })
  }

  const handleDynamics = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setForm({
      ...form,
      dynamics: value,
    })
  }

  useEffect(() => {
    const updateDimension = () => {
      const currentDimension = getCurrentDimension()

      setMinBoxWidth(getMinBoxWidth(currentDimension))
      setScreenSize(currentDimension)
    }
    window.addEventListener('resize', updateDimension)

    return () => {
      window.removeEventListener('resize', updateDimension)
    }
  }, [screenSize])

  //const result = symptoms.find((obj) => obj.symptomId === currentSymptom && obj.existence === existence && obj.intensity === intensity && obj.dynamics === dynamics);
  //const symptomImage = SymptomImagesList.find((obj) => obj.symptomId === symptomId);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={'auto'} style={{ width: DEFAULT_BOX_WIDTH }}>
            <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: '100%' }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    label='Vārds / Pacienta numurs'
                    fullWidth
                    error={errors.pacientNR}
                    name={'pacientNR'}
                    value={form.pacientNR}
                    onChange={handlerChangeText}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth size='small'>
                    <InputLabel>Dzimums</InputLabel>
                    <Select
                      label='Dzimums'
                      value={form.genderType ? form.genderType : ''}
                      error={errors.genderType}
                      name={'genderType'}
                      onChange={handleChangeGenderType}
                    >
                      {Object.entries(GenderType).map((item) => (
                        <MenuItem value={item[0]} key={item[1]}>
                          {item[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth size='small'>
                    <InputLabel>Vecums</InputLabel>
                    <Select
                      label='Vecums'
                      value={form.age}
                      error={errors.age}
                      name={'age'}
                      onChange={handleChangeSelect}
                    >
                      {AgeList.map((number) => (
                        <MenuItem value={number} key={'age' + number}>
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} sx={{ paddingTop: 0, overflow: 'usnet' }}>
                      <DatePicker
                        format={DATE_FORMAT}
                        displayWeekNumber={true}
                        showDaysOutsideCurrentMonth={true}
                        maxDate={today}
                        minDate={lastDays}
                        label='Simptoma uzsākšana'
                        slotProps={{ textField: { size: 'small', fullWidth: true, error: errors.symptomDate } }}
                        value={form.symptomDate.length === 0 ? null : dayjs(form.symptomDate)}
                        name={'symptomDate'}
                        onChange={(v) => handlerChangeDate(v, 'symptomDate')}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} style={{ alignSelf: 'center' }}>
                      <Typography>Visaugstākā</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        size='small'
                        label='temperatūra'
                        fullWidth
                        error={errors.temperature}
                        name={'temperature'}
                        onChange={handlerChangeText}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']} sx={{ paddingTop: 0, overflow: 'usnet' }}>
                          <DatePicker
                            format={DATE_FORMAT}
                            displayWeekNumber={true}
                            showDaysOutsideCurrentMonth={true}
                            maxDate={today}
                            minDate={lastDays}
                            label='Sasniedza'
                            slotProps={{ textField: { size: 'small', fullWidth: true, error: errors.temperatureDate } }}
                            value={form.temperatureDate.length === 0 ? null : dayjs(form.temperatureDate)}
                            name={'temperatureDate'}
                            onChange={(v) => handlerChangeDate(v, 'temperatureDate')}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}
                    error={errors.covidTest}
                  >
                    <FormLabel>Pārbaude uz Covid</FormLabel>
                    <RadioGroup
                      style={{ display: 'inline-block', marginLeft: 10 }}
                      row
                      value={form.covidTest}
                      name={'covidTest'}
                      onChange={handleChangeCovidTest}
                    >
                      <FormControlLabel value={false} control={<Radio color='warning' />} label='Neveikts' />
                      <FormControlLabel value={true} control={<Radio color='success' />} label='Veikts' />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {form.covidTest === true && (
                  <>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='success'
                            size='small'
                            checked={form.selfTest}
                            onChange={(e, checked) => {
                              handleChangeCheckbox('selfTest', checked)
                            }}
                          />
                        }
                        label='Paštests&nbsp;'
                      />
                    </Grid>

                    {form.selfTest ? (
                      <>
                        <Grid item xs={3}>
                          <FormControl fullWidth size='small'>
                            <InputLabel>Rezultāts</InputLabel>
                            <Select
                              label='Rezultāts'
                              value={form.selfTestResult}
                              error={errors.selfTestResult}
                              name={'selfTestResult'}
                              onChange={handleChangeSelect}
                            >
                              {Object.entries(PositiveNegativeType).map((item) => (
                                <MenuItem value={item[0]} key={item[1]}>
                                  {item[1]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} sx={{ paddingTop: 0, overflow: 'usnet' }}>
                              <DatePicker
                                format={DATE_FORMAT}
                                displayWeekNumber={true}
                                showDaysOutsideCurrentMonth={true}
                                maxDate={today}
                                minDate={lastDays}
                                label='Testa datums'
                                slotProps={{
                                  textField: { size: 'small', fullWidth: true, error: errors.selfTestDate },
                                }}
                                value={form.selfTestDate.length === 0 ? null : dayjs(form.selfTestDate)}
                                name={'selfTestDate'}
                                onChange={(v) => handlerChangeDate(v, 'selfTestDate')}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={9}></Grid>
                    )}

                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='success'
                            size='small'
                            checked={form.labaratoryTest}
                            onChange={(e, checked) => {
                              handleChangeCheckbox('labaratoryTest', checked)
                            }}
                          />
                        }
                        label='Labaratorijā'
                      />
                    </Grid>

                    {form.labaratoryTest ? (
                      <>
                        <Grid item xs={3}>
                          <FormControl fullWidth size='small'>
                            <InputLabel>Rezultāts</InputLabel>
                            <Select
                              label='Rezultāts'
                              value={form.labaratoryTestResult}
                              error={errors.labaratoryTestResult}
                              name={'labaratoryTestResult'}
                              onChange={handleChangeSelect}
                            >
                              {Object.entries(PositiveNegativeType).map((item) => (
                                <MenuItem value={item[0]} key={item[1]}>
                                  {item[1]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} sx={{ paddingTop: 0, overflow: 'usnet' }}>
                              <DatePicker
                                format={DATE_FORMAT}
                                displayWeekNumber={true}
                                showDaysOutsideCurrentMonth={true}
                                maxDate={today}
                                minDate={lastDays}
                                label='Testa datums'
                                slotProps={{
                                  textField: { size: 'small', fullWidth: true, error: errors.labaratoryTestDate },
                                }}
                                value={form.labaratoryTestDate.length === 0 ? null : dayjs(form.labaratoryTestDate)}
                                name={'labaratoryTestDate'}
                                onChange={(v) => handlerChangeDate(v, 'labaratoryTestDate')}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={9}></Grid>
                    )}
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={'auto'} style={{ width: DEFAULT_BOX_WIDTH }}>
            <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: '100%' }}>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <FormControlLabel
                  control={<Checkbox color='success' size='small' />}
                  label='Ir izdarīta sezonālā vakcīna pret gripu'
                  checked={form.seasonalVaccine}
                  onChange={(e, checked) => {
                    handleChangeCheckbox('seasonalVaccine', checked)
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <FormControlLabel
                  control={<Checkbox color='success' size='small' />}
                  label='Ir izdarīta pneimokoku vakcīna'
                  checked={form.pneumococcalVaccine}
                  onChange={(e, checked) => {
                    handleChangeCheckbox('pneumococcalVaccine', checked)
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{}}>
                <Typography>Ārstēšanai pielietotaās zāles</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={4} style={{ textAlign: 'left' }}>
                    <FormControlLabel
                      control={<Checkbox color='success' size='small' />}
                      label='pret drudžu'
                      checked={form.medicinesFever}
                      onChange={(e, checked) => {
                        handleChangeCheckbox('medicinesFever', checked)
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} style={{ textAlign: 'left' }}>
                    <FormControlLabel
                      control={<Checkbox color='success' size='small' />}
                      label='pret klepu'
                      checked={form.medicinesCough}
                      onChange={(e, checked) => {
                        handleChangeCheckbox('medicinesCough', checked)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'left' }}>
                    <FormControlLabel
                      control={<Checkbox color='success' size='small' />}
                      label='pret sāpēm'
                      checked={form.medicinesPain}
                      onChange={(e, checked) => {
                        handleChangeCheckbox('medicinesPain', checked)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      label='Citas pielietotaās zāles'
                      //error={errors.medicinesOther}
                      name={'medicinesOther'}
                      onChange={handlerChangeText}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {parseInt(form.age) >= SMOKE_FROM && (
                <Grid item xs={12} marginTop={2}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <FormControl
                        style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}
                        error={errors.smoke}
                      >
                        <FormLabel>Smēķē</FormLabel>
                        <RadioGroup
                          style={{ display: 'inline-block', marginLeft: 10 }}
                          row
                          value={form.smoke}
                          name={'smoke'}
                          onChange={handleChangeSmoke}
                        >
                          <FormControlLabel value={false} control={<Radio color='success' />} label='Nē' />
                          <FormControlLabel value={true} control={<Radio color='warning' />} label='Jā' />
                        </RadioGroup>
                      </FormControl>
                      {form.smoke === true && (
                        <Grid container spacing={1}>
                          <Grid item xs={6} style={{ alignSelf: 'center' }}>
                            <Typography>Cik dienā</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                              <Select
                                label='Vecums'
                                value={form.smokePerDay}
                                error={errors.smokePerDay}
                                name={'smokePerDay'}
                                onChange={handleChangeSelect}
                              >
                                {AgeList.map((number) => (
                                  <MenuItem value={number} key={'smokePerDay' + number}>
                                    {number}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <FormControl
                        style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}
                        error={errors.vape}
                      >
                        <FormLabel>Lieto veipi</FormLabel>
                        <RadioGroup
                          style={{ display: 'inline-block', marginLeft: 10 }}
                          row
                          value={form.vape}
                          name={'vape'}
                          onChange={handleChangeVape}
                        >
                          <FormControlLabel value={false} control={<Radio color='success' />} label='Nē' />
                          <FormControlLabel value={true} control={<Radio color='warning' />} label='Jā' />
                        </RadioGroup>
                      </FormControl>
                      {form.vape === true && (
                        <FormControlLabel
                          control={<Checkbox color='success' size='small' />}
                          label='gadu garumā'
                          checked={form.vapeYear}
                          onChange={(e, checked) => {
                            handleChangeCheckbox('vapeYear', checked)
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>

          {currentSymptom ? (
            <>
              <Grid item xs={'auto'} style={{ width: minBoxWidth, textAlign: 'left' }}>
                <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: '100%' }}>
                  <Typography style={{fontWeight: 900, color: '#0779E9'}}>Simptoms: {currentSymptom.symptomName}</Typography>
                </Paper>
              </Grid>

              <Grid item xs={'auto'} style={{ width: DEFAULT_BOX_WIDTH / 2 }}>
                <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: 235 }}>
                  {symptomImage && (
                    <img src={symptomImage.source} alt={symptomImage.symptomName} style={{ marginTop: 20 }} />
                  )}
                </Paper>
              </Grid>

              <Grid item xs={'auto'} style={{ width: DEFAULT_BOX_WIDTH / 2 }}>
                <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: 235 }}>
                  <FormControl style={{ width: '100%' }} error={errors.existence}>
                    <FormLabel>Simptomu esamība</FormLabel>
                    <RadioGroup value={form.existence} onChange={handleExistence}>
                      <FormControlLabel value='now' control={<Radio />} label='Ir tagad' />
                      <FormControlLabel value='was' control={<Radio />} label='Ir bijis, pārgāju' />
                      <FormControlLabel value='never' control={<Radio />} label='Nav, nav bijis' />
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={'auto'} style={{ width: DEFAULT_BOX_WIDTH / 2 }}>
                <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: 235 }}>
                  {showIntensity && (
                    <FormControl style={{ width: '100%' }} error={errors.intensity}>
                      <FormLabel>Intensitāte / izteiktbība</FormLabel>
                      <RadioGroup value={form.intensity} onChange={handleIntensity}>
                        <FormControlLabel
                          value='red'
                          control={<Radio />}
                          label={<div style={{ width: 150, height: 30, backgroundColor: 'red' }}></div>}
                        />
                        <FormControlLabel
                          value='orange'
                          control={<Radio />}
                          label={<div style={{ width: 100, height: 30, backgroundColor: 'orange' }}></div>}
                        />
                        <FormControlLabel
                          value='yellow'
                          control={<Radio />}
                          label={<div style={{ width: 50, height: 30, backgroundColor: 'yellow' }}></div>}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={'auto'} style={{ width: DEFAULT_BOX_WIDTH / 2 }}>
                <Paper elevation={5} style={{ margin: 0, padding: '10px 8px 0 10px', height: 235 }}>
                  {showDynamics && (
                    <FormControl style={{ width: '100%' }} error={errors.dynamics}>
                      <FormLabel>Simptoma attīstības dinamika</FormLabel>
                      <RadioGroup value={form.dynamics} onChange={handleDynamics}>
                        <FormControlLabel
                          value={'hight'}
                          control={<Radio />}
                          label={<img src={dynamicsHight} alt={'hight'} />}
                        />
                        <FormControlLabel
                          value={'middle'}
                          control={<Radio />}
                          label={<img src={dynamicsMiddle} alt={'middle'} />}
                        />
                        <FormControlLabel
                          value={'low'}
                          control={<Radio />}
                          label={<img src={dynamicsLow} alt={'low'} />}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={'auto'} style={{ width: minBoxWidth }}>
                <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: '100%' }}>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    label='Šeit jūs varat brīvi aprakstīt simtomus, kas nav aprakstīti anketā'
                    error={errors.comments}
                    name={'comments'}
                    onChange={handlerChangeText}
                  />
                </Paper>
              </Grid>

              <Grid item xs={'auto'} style={{ width: minBoxWidth }}>
                <Paper
                  elevation={5}
                  style={{ margin: 0, padding: '10px 10px 0 10px', height: '100%', textAlign: 'right' }}
                >
                  <Button
                    disabled={currentSymptomKey >= symptomsList.length - 1}
                    onClick={handleNextSymptom}
                    variant='contained'
                    color='primary'
                    style={{ width: 200, marginRight: 10 }}
                  >
                    Nākošais ({currentSymptomKey + 1} / {symptomsList.length})
                  </Button>
                  <Button
                    disabled={currentSymptomKey < symptomsList.length - 1}
                    onClick={handleSaveForm}
                    variant='contained'
                    color='success'
                    style={{ width: 200 }}
                    endIcon={<SendIcon />}
                  >
                    Nosūtīt ārstam
                  </Button>
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={'auto'} style={{ width: minBoxWidth }}>
              <Paper elevation={5} style={{ margin: 0, padding: '10px 10px 0 10px', height: '100%' }}>
                <Typography>Loading...</Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  )
}

export default Questionnaire
