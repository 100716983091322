import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import RootPage from '../page/RootPage'
import Questionnaire from '../components/questionnaire'
import ListQuestionnaires from '../components/ListQuestionnaires'

export const routerConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootPage />}>
      <Route path='*' element={<Questionnaire />} />
      <Route index element={<Questionnaire />} />
      <Route path='new' element={<Questionnaire />} />
      <Route path='list' element={<ListQuestionnaires />} />
    </Route>,
  ),
)
