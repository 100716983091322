import { FC } from 'react'
import { RouterProvider } from 'react-router-dom'
import { routerConfig } from './Router'

import { Box } from '@mui/material'

import { IS_CHROME } from './components/utils'

const App: FC = () => {
  return (
    <Box
      //justifyContent={'center'} display={'flex'}
      sx={{
        margin: '10px 0 0 0',
        textAlign: IS_CHROME ? '-webkit-center' : '-moz-center',
        //textAlign: '-moz-center',
        //display: 'flex',
        //flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          maxWidth: 1000,
          //height: 128,
        },
      }}
    >
      <RouterProvider router={routerConfig} />
    </Box>
  )
}

export default App
