import { FC } from 'react'
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { Button, TextField, Typography } from '@mui/material'
import { apiUrl } from '../API/GetSymptoms'

export interface ListQuestionnairesProps {
  getList: any[]
  getSymptoms: any[]
}

const ListQuestionnairesComponent: FC<ListQuestionnairesProps> = ({ getList, getSymptoms }) => {
  const columns: GridColDef[] = [
    //{ field: 'id', headerName: 'ID', width: 70 },
    { field: 'pacientNR', headerName: 'Pacienta numurs', width: 100 },
    {
      field: 'genderType',
      headerName: 'Dzimums',
      width: 80,
      valueGetter: (params: GridValueGetterParams) => (params.row.genderType === 'FEMALE' ? 'Sieviete' : 'Vīrietis'),
    },
    { field: 'age', headerName: 'Vecums', type: 'number', width: 70 },
    { field: 'fillDate', headerName: 'Aizpildīšanas datums', type: 'number', width: 100 },
    { field: 'symptomDate', headerName: 'Simptoma uzsākšana', type: 'number', width: 100 },
    { field: 'daysCount', headerName: 'Dienas', type: 'number', width: 70,
      renderCell: (params: GridRenderCellParams) => {
        const symptomDate = params.row.symptomDate.split('/')

        const start = new Date()
        const end = new Date(symptomDate[2] + '-' + symptomDate[0] + '-' + symptomDate[1]);
        
        const diff = (start.getTime() - end.getTime())  / 100

        return <>{Math.round(diff / (24 * 60 * 60 * 60))}</>
      },
    },
    { field: 'temperature', headerName: 'Temperatūra', type: 'number', width: 100 },
    { field: 'temperatureDate', headerName: 'Sasniedza', type: 'number', width: 100 },
    {
      field: 'fullAnswer',
      headerName: 'Simptomu atbildes',
      type: 'number',
      width: 650,
      renderCell: (params: GridRenderCellParams) => {
        const sicknest_answer = params.row.sicknest_answer
        let value = ''

        for (let i = 0; i < sicknest_answer.length; i++) {
          const symptom = (getSymptoms || []).find(
            (item) =>
              item.symptomId === sicknest_answer[i].symptomId &&
              item.existence === sicknest_answer[i].existence &&
              item.intensity === sicknest_answer[i].intensity &&
              item.dynamics === sicknest_answer[i].dynamics,
          )

          if (symptom && symptom.text) {
            value = value + symptom.text + '\n'
          }
        }

        return <TextField size='small' fullWidth multiline value={value} rows={3} onFocus={(e) => {
          e.target.select()
          document.execCommand('copy');
        }}/>
      },
    },
    {
      field: 'seasonalVaccine',
      headerName: 'Gripu',
      type: 'number',
      width: 70,
      valueGetter: (params: GridValueGetterParams) => (params.row.seasonalVaccine === '1' ? 'izdarīta' : ''),
    },
    {
      field: 'pneumococcalVaccine',
      headerName: 'Pneimokoku',
      type: 'number',
      width: 90,
      valueGetter: (params: GridValueGetterParams) => (params.row.pneumococcalVaccine === '1' ? 'izdarīta' : ''),
    },
    {
      field: 'covidTest',
      headerName: 'Covid test',
      type: 'number',
      width: 90,
      valueGetter: (params: GridValueGetterParams) => (params.row.covidTest === '1' ? 'izdarīts' : ''),
    },
    {
      field: 'selfTest',
      headerName: 'Paštests',
      type: 'number',
      width: 70,
      valueGetter: (params: GridValueGetterParams) => (params.row.selfTest === '1' ? 'izdarīts' : ''),
    },
    {
      field: 'selfTestResult',
      headerName: 'Rezultāts',
      type: 'number',
      width: 90,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.selfTestResult === 'POSITIVE' ? 'POZITĪVS' : 'negatīvs',
    },
    { field: 'selfTestDate', headerName: 'Testa datums', type: 'number', width: 100 },
    {
      field: 'labaratoryTest',
      headerName: 'Labaratorijā',
      type: 'number',
      width: 90,
      valueGetter: (params: GridValueGetterParams) => (params.row.labaratoryTest === '1' ? 'izdarīts' : ''),
    },
    {
      field: 'labaratoryTestResult',
      headerName: 'Rezultāts',
      type: 'number',
      width: 90,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.labaratoryTestResult === 'POSITIVE' ? 'POZITĪVS' : 'negatīvs',
    },
    { field: 'labaratoryTestDate', headerName: 'Testa datums', type: 'number', width: 100 },
    {
      field: 'answer',
      headerName: 'Simptomu atbildes',
      type: 'number',
      width: 350,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography fontSize={14} fontWeight={700} lineHeight={'40px'} fontFamily={'monospace'}>
            {params.row.answer}
          </Typography>
        )
      },
    },
  ]

  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        getRowHeight={() => 'auto'}
        rows={getList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        //checkboxSelection
      />

      <Button
        variant='contained'
        style={{ width: 120 }}
        href={apiUrl + 'userAPI.php?controller=sicknesController&method=getCSV'}
      >
        GET CSV
      </Button>
    </div>
  )
}

export default ListQuestionnairesComponent
